import { Button, Divider, Flex, Text, Textarea, TextInput, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconInfoCircle, IconPlus } from '@tabler/icons-react';

import { validateKeywords, validateName } from './validate';

const amountOfKeywords = (keywords: string): number =>
  keywords.length === 0 ? 0 : keywords.split(',').length;

const DefineVariant = () => {
  const form = useForm({
    initialValues: {
      name: '',
      keywords: '',
    },
    validate: {
      name: validateName,
      keywords: validateKeywords,
    },
    validateInputOnChange: true,
  });

  return (
    <>
      <Text mt={16} fz={14}>
        Page variants allow you to manage multiple themed pages, such as those for different
        locations or personas, using a single template.
      </Text>
      <Text mt={16} fz={14}>
        Upflowy can use AI to automatically generate these pages from one blueprint, simplifying the
        management of variant campaigns.
      </Text>

      <TextInput
        mt={16}
        label={
          <Flex align="center" gap={4}>
            Variant name
            <Tooltip
              fz={12}
              withinPortal={false}
              label={
                <>
                  These keywords will be used to generate
                  <br />
                  unique pages based on your variant type
                </>
              }
            >
              <IconInfoCircle size={16} />
            </Tooltip>
          </Flex>
        }
        description="Give this variant a clear, descriptive name to easily identify it later"
        {...form.getInputProps('name')}
      />

      <Textarea
        mt={16}
        mb={4}
        rows={8}
        label="Add variant keywords"
        description="Enter up to 200 keywords, separated by commas that define your page variants."
        {...form.getInputProps('keywords')}
      />

      {form.isValid('keywords') && (
        <Text fz={12} ta="right" c="var(--mantine-color-dimmed)">
          {amountOfKeywords(form.values.keywords)} keyword used
        </Text>
      )}

      <Divider mx={-16} mt={32} />

      <Button mt={16} w="100%" leftSection={<IconPlus />} disabled={!form.isValid()}>
        Generate variants
      </Button>
    </>
  );
};

export default DefineVariant;
