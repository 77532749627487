import { Collapse, Text } from '@mantine/core';
import { IconLayersDifference } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import {
  ActiveBar,
  ButtonContentSubMenu,
  StyledButtonSubMenu,
  SubMenuContainer,
} from '../Toolbar.styles';
import { subPanelCollection } from '../useToolbarOperations';

import { MakeOpenSubPanelObj, SubPanelTypes } from '~/global.types';

interface OptimizePageMenuProps {
  opened: boolean;
  makeOpenSubPanel?: MakeOpenSubPanelObj;
}

const OptimizePageMenu = ({ opened, makeOpenSubPanel }: OptimizePageMenuProps) => {
  const [openedSub, setOpenedSub] = useState('');

  useEffect(() => {
    setOpenedSub(makeOpenSubPanel?.type || '');
  }, [makeOpenSubPanel]);

  const handleClickToToggleSubPanel = (panelName: SubPanelTypes) => {
    setOpenedSub(openedSub === panelName ? '' : panelName || '');
  };

  return (
    <Collapse in={opened} transitionDuration={100} transitionTimingFunction="linear">
      <SubMenuContainer style={{ borderRadius: '0px 4px 4px 4px' }}>
        <StyledButtonSubMenu
          onClick={() => handleClickToToggleSubPanel('page-variants')}
          aria-label="Page variants"
          variant="transparent"
          color="Gray/Gray 8"
        >
          <ButtonContentSubMenu className={'page-variants' === openedSub ? 'opened' : ''}>
            <IconLayersDifference size={16} />
            <Text size="10px" fw={500}>
              Variants
            </Text>
            <ActiveBar className={'page-variants' === openedSub ? 'opened' : ''}></ActiveBar>
          </ButtonContentSubMenu>
        </StyledButtonSubMenu>
      </SubMenuContainer>

      {Object.entries(subPanelCollection).map(([key, obj]) => {
        const Panel = obj.component;
        return (
          <Panel
            key={key}
            opened={openedSub === key}
            handleClickToToggleSubPanel={handleClickToToggleSubPanel}
            dataFromPostMessage={makeOpenSubPanel?.data}
            viewerDataFromPostMessage={makeOpenSubPanel?.viewerData}
          />
        );
      })}
    </Collapse>
  );
};

export default OptimizePageMenu;
