import { useHotkeys } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import ShortUniqueId from 'short-unique-id';

import { GENERIC_BODY_TEXT_UNIT_STYLE } from '~/constants';
import { PageJsonSnippetObj, ViewerData } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

const { randomUUID } = new ShortUniqueId();

// This ensure all nested children's selector is unique
export const recursiveUpdateSelector = (obj: PageJsonSnippetObj): PageJsonSnippetObj => {
  const updatedObject = JSON.parse(JSON.stringify(obj));

  for (const key in updatedObject) {
    if (key === 'selector') {
      updatedObject[key] = `elem-${randomUUID(8)}`;
    } else if (typeof updatedObject[key] === 'object') {
      updatedObject[key] = recursiveUpdateSelector(obj[key] as PageJsonSnippetObj);
    }
  }

  return updatedObject;
};

export const parseOrReturnString = (str: string): object | string => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

const useCopyPaste = (selectedData: Partial<ViewerData>) => {
  const { elementSelector, columnLayoutInnerComponentType } = selectedData;
  const [selectedPageData, setSelectedPageData] = useState<PageJsonSnippetObj>({});

  const handleKeyToCopy = () => {
    if (elementSelector && selectedPageData) {
      const viewer = document.querySelector(`#${selectedData.viewer}`) as HTMLIFrameElement;
      const range = viewer.contentDocument?.getSelection();

      // Copy the entire text unit if there is no range select
      if (range?.isCollapsed) {
        navigator.clipboard.writeText(JSON.stringify(selectedPageData));
      } else {
        navigator.clipboard.writeText(range?.toString() || '');
      }
    }
  };

  /**
   * This manage content pasting into section columns. Pasting text into EditorJS
   * is handled from the unitBridgePlugin.ts
   */
  const handleKeyToPaste = async () => {
    //try catch to handle focus error when accessing clipboard
    try {
      const addIntoColStr = columnLayoutInnerComponentType?.split('-')[1];
      const fromClipboard = parseOrReturnString(await navigator.clipboard.readText());

      if (fromClipboard) {
        let addNode: string | PageJsonSnippetObj;

        // This could be a copied string outside Studio app. This would turn it into
        // standard text unit data
        if (typeof fromClipboard === 'string') {
          addNode = {
            tagName: 'div',
            textValue: fromClipboard,
            style: GENERIC_BODY_TEXT_UNIT_STYLE,
          };
        } else {
          addNode = recursiveUpdateSelector(fromClipboard as PageJsonSnippetObj);
        }

        msg({
          type: 'add-page-element',
          messageData: { data: selectedData },
          addNode,
          addIntoCol: parseInt(addIntoColStr || 'NaN', 10),
        });
      }
    } catch (e) {
      notifications.show({
        title: 'Error',
        message: 'Unable to paste content, please try again',
        color: 'red',
      });
    }
  };

  useViewerMessage(
    ({ data }) => {
      if (data.type === 'receive-selected-page-data' && data.result) {
        setSelectedPageData(data.result);
      }

      // If the window focus is applied inside viewer iframe, it need to listen to this message
      if (data.type === 'viewer-trigger-keydown-copy') {
        handleKeyToCopy();
      }
    },
    [selectedPageData],
  );

  return useHotkeys([
    ['mod+c', handleKeyToCopy],
    ['mod+v', handleKeyToPaste],
  ]);
};

export default useCopyPaste;
