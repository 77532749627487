import { PageJsonSnippetObj } from '~/global.types';
import { findAndChangePageData } from '~/helpers/pageDataTraverse/pageDataTraverse';
import { BlockOutput } from '~/messages.types';

const backgroundHandling = (data: BlockOutput['data']) => ({
  ...(data?.backgroundColor && {
    'background-color': data.backgroundColor,
    'background-image': undefined,
    'background-size': undefined,
    background: undefined,
  }),
  ...(data?.backgroundImage && {
    'background-image': data.backgroundImage,
    'background-color': undefined,
    'background-size': 'cover',
    background: undefined,
  }),
  ...(data?.backgroundSize && { 'background-size': data.backgroundSize }),
  ...(data?.backgroundRepeat && { 'background-repeat': data.backgroundRepeat }),
  ...(data?.backgroundPosition && { 'background-position': data.backgroundPosition }),
});

const mutateObject = (
  updatedObject: PageJsonSnippetObj,
  { data, stylesOveride, jsonOverride }: BlockOutput,
) =>
  JSON.parse(
    JSON.stringify({
      ...updatedObject,
      ...(data?.text && { textValue: data.text }),
      ...(data?.text === '' && { textValue: '' }),
      ...(data?.tagName && { tagName: data.tagName }),
      ...(data?.dataImageSrc && { '@data-image-src': data.dataImageSrc }),

      style: {
        ...updatedObject.style,
        ...backgroundHandling(data),
        ...(data?.alignment && { 'text-align': data.alignment }),
        ...(data?.lineHeight && { 'line-height': data.lineHeight }),
        ...(data?.padding && { padding: data.padding }),
        ...(data?.alignSelf && { 'align-self': data.alignSelf }),
        ...(data?.color && { color: data.color }),
        ...(data?.fontSize && { 'font-size': data.fontSize }),
        ...(data?.border && { border: data.border }),
        ...(data?.borderRadius && { 'border-radius': data.borderRadius }),
      },
      ...(stylesOveride && { ...stylesOveride }),
      ...(jsonOverride && { ...jsonOverride }),
    }),
  );

const mutateColumnLayoutObject = (
  updatedObject: PageJsonSnippetObj,
  forSyntax: string,
  { data }: BlockOutput,
) => ({
  ...updatedObject,
  [`style_for_${forSyntax}`]: {
    ...(updatedObject[`style_for_${forSyntax}`] as Record<string, string>),
    ...backgroundHandling(data),
    ...(data?.padding && { padding: data.padding }),
    ...(data?.border && { border: data.border }),
    ...(data?.borderRadius && { 'border-radius': data.borderRadius }),
    ...(data?.justifyContent && { 'justify-content': data.justifyContent }),
  },
});

function adjustSlots(jsonObj: PageJsonSnippetObj, slotOfDeletedNode: string) {
  const slotShouldStillExist = jsonObj.contains?.some(
    (node: PageJsonSnippetObj) => node['@slot'] === slotOfDeletedNode,
  );

  if (jsonObj.contains && !slotShouldStillExist) {
    const columnToRemove = parseInt(slotOfDeletedNode.split('-')[1], 10);

    jsonObj.contains.forEach((node: PageJsonSnippetObj) => {
      if (node['@slot']) {
        const nodeCol = parseInt((node['@slot'] as string).split('-')[1], 10);
        if (nodeCol >= columnToRemove) {
          node['@slot'] = `column-${nodeCol - 1}`;
        }
      }
    });

    const styleKeys = Object.keys(jsonObj)
      .filter((key) => key.startsWith('style_for_.column-'))
      .sort((a, b) => parseInt(a.split('-')[1], 10) - parseInt(b.split('-')[1], 10));

    for (let i = 0; i < styleKeys.length; i++) {
      const currentColumnNumber = parseInt(styleKeys[i].split('-')[1], 10);
      if (currentColumnNumber >= columnToRemove) {
        const nextColumnNumber = currentColumnNumber + 1;
        const currentStyleKey = `style_for_.column-${currentColumnNumber}`;
        const nextStyleKey = `style_for_.column-${nextColumnNumber}`;

        if (jsonObj[nextStyleKey]) {
          jsonObj[currentStyleKey] = jsonObj[nextStyleKey];
          delete jsonObj[nextStyleKey];
        } else {
          delete jsonObj[currentStyleKey];
        }
      }
    }
  }

  return jsonObj;
}

function modify(obj: PageJsonSnippetObj, editorBlockObj: BlockOutput) {
  const result = findAndChangePageData<BlockOutput>(
    obj,
    editorBlockObj,
    (updatedObject, { toDelete, forSyntax }) => {
      if (updatedObject.isDelete) return updatedObject;
      // Mark deletion if all content is removed
      if (toDelete) {
        return { isDeleted: true, deletedSlot: updatedObject['@slot'] };
      } else if (forSyntax) {
        // Column layout related will have a forSyntax to formulate properties like "style_for_section" etc
        return mutateColumnLayoutObject(updatedObject, forSyntax, editorBlockObj);
      } else {
        return mutateObject(updatedObject, editorBlockObj);
      }
    },
  );

  return result.fullUpdatedObject;
}

function cleanEmptyColumnLayout(obj: PageJsonSnippetObj) {
  const updatedObject = JSON.parse(JSON.stringify(obj));

  if (updatedObject.contains && Array.isArray(updatedObject.contains)) {
    updatedObject.contains = updatedObject.contains
      .map(cleanEmptyColumnLayout)
      .filter((child: PageJsonSnippetObj | null) => child !== null);
  }

  if (
    updatedObject.layoutName === 'column-layout' &&
    (!updatedObject.contains || updatedObject.contains.length === 0)
  ) {
    return null;
  }

  return updatedObject;
}

function postProcessAfterDelete(obj: PageJsonSnippetObj) {
  let updatedObject = JSON.parse(JSON.stringify(obj));

  for (const key in updatedObject) {
    if (typeof updatedObject[key] === 'object') {
      if (Array.isArray(updatedObject[key])) {
        const slotOfDeletedNode = updatedObject[key].find(
          (node: PageJsonSnippetObj) => node.deletedSlot,
        )?.deletedSlot;

        if (slotOfDeletedNode) {
          // Adjust the slot for each element if any has marked deleted
          updatedObject = adjustSlots(updatedObject, slotOfDeletedNode);
        }

        // Delete all marked deleted object
        updatedObject[key] = updatedObject[key].filter(
          (arrayObj: Record<string, PageJsonSnippetObj>) => !arrayObj.isDeleted,
        );
      }

      updatedObject[key] = postProcessAfterDelete(updatedObject[key]);
    }
  }

  return updatedObject;
}

function modifyPageData(obj: PageJsonSnippetObj, editorBlockObj: BlockOutput) {
  const result = modify(obj, editorBlockObj);
  let newresult;
  if (editorBlockObj.toDelete) {
    newresult = postProcessAfterDelete(result);
    newresult = cleanEmptyColumnLayout(newresult);
  } else {
    newresult = result;
  }
  return newresult;
}

export default modifyPageData;
