import { Box, Button, Divider } from '@mantine/core';
import { IconLayersDifference } from '@tabler/icons-react';

import msg from '~/helpers/viewerInteractions/msg';

const VariantMenuExtension = () => {
  const handleClickToOpenPanel = () => {
    msg({
      type: 'open-toolbar-panel',
      openToolbarPanel: 'optimize-page',
      openToolbarSubPanel: 'page-variants',
    });
  };

  return (
    <>
      <Divider orientation="vertical" />
      <Box px={8}>
        <Button leftSection={<IconLayersDifference />} radius={4} onClick={handleClickToOpenPanel}>
          Create variant
        </Button>
      </Box>
    </>
  );
};

export default VariantMenuExtension;
