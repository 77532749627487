import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

type WorkspaceContextData = Partial<{
  workspaceId: string;
  // The site provisioning domain
  siteProvisioningUrl: string;
}>;

type WorkspaceContextType =
  | null
  | [WorkspaceContextData, React.Dispatch<React.SetStateAction<WorkspaceContextData>>];

const WorkspaceContext = createContext<WorkspaceContextType>(null);

const cname =
  import.meta.env?.VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME ?? '*.test.upflowyexperience.com';

const devSiteProvisioningCname = import.meta.env?.VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME;

// eslint-disable-next-line react-refresh/only-export-components
export const useStudioWorkspace = () => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error('useStudioWorkspace must be used within a WorkspaceProvider');
  }
  return context;
};

export interface MediaCollectionItem {
  tagName?: string;
  '@src'?: string;
  '@alt'?: string;
}

export const WorkspaceProvider = ({ children }: PropsWithChildren) => {
  const [workspace, setWorkspace] = useState<WorkspaceContextData>({});
  const { workspaceId } = workspace;

  useEffect(() => {
    if (workspaceId) {
      const siteProvisioningUrl = `https://${(devSiteProvisioningCname || cname).replace(
        '*',
        workspaceId,
      )}/${workspaceId}/`;

      setWorkspace({
        ...workspace,
        siteProvisioningUrl,
      });
    }
  }, [workspaceId]);

  return (
    <WorkspaceContext.Provider value={[workspace, setWorkspace]}>
      {children}
    </WorkspaceContext.Provider>
  );
};
