import styled from 'styled-components';

export const PageNameField = styled.div`
  align-self: stretch;
  border-radius: var(--br-9xs) var(--br-9xs) 0px 0px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) 0rem var(--padding-9xs) var(--padding-5xs);
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  gap: var(--gap-5xs);
  font-size: 14px;
  color: rgb(72, 69, 78);
`;

export const ResizableTextInput = styled.div.attrs(({ contentEditable = true }) => ({
  contentEditable,
  spellCheck: false,
  suppressContentEditableWarning: true,
  onKeyDown: (e) => e.key === 'Enter' && (e.target as HTMLDivElement).blur(),
}))`
  outline: none;
  min-width: 5rem;
`;
