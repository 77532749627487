import { createGlobalStyle } from 'styled-components';

import { IS_SAFARI } from './constants';

export default createGlobalStyle`
  body {
    margin: 0;
    line-height: normal;
  }

  body::before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: var(--m3-sys-light-surface-container-high);
    z-index: -1;
  }

  .cm-editor .cm-scroller {
    font-size: 12px;
  }

  .react-transform-component {
    width: 100vw;
    height: 100vh;
    backface-visibility: hidden;
    will-change: transform;
    ${IS_SAFARI ? '' : 'transition: transform 60ms linear;'}
  }

  .mantine-ColorInput-dropdown {
    z-index: 10000 !important;
  }

  :root {

  /* fonts */
  --m3-label-large: Rubik;
  --font-inherit: inherit;

  /* font sizes */
  --m3-label-large-size: 0.88rem;
  --m3-label-medium-size: 0.75rem;
  --m3-body-small-size: 0.75rem;
  --m3-body-large-size: 1rem;
  --m3-headline-large-size: 2rem;
  --m3-display-small-size: 2.25rem;

  /* Colors */
  --m3-sys-light-primary: #6931ed;
  --m3-sys-light-on-primary: #fff;
  --m3-sys-light-primary-hover: color-mix(in srgb, var(--m3-sys-light-primary) 88%, #fff);
  --m3-sys-light-secondary-container: #e1e0ff;
  --m3-sys-light-on-secondary-container: #070764;
  --m3-sys-light-on-surface-variant: #48454e;
  --m3-state-layers-light-on-surface-variant-opacity-012: rgba(73, 69, 79, 0.12);
  --m3-sys-light-surface-container-high: linear-gradient(360deg, #EDE2FF 0%, #FFFFFF 32.78%);
  --m3-sys-light-surface-container-highest: #e1e3e3;
  --m3-sys-light-surface-dim: #d8dadb;
  --m3-sys-light-outline: #79757f;
  --m3-sys-light-on-surface: #191c1d;
  --m3-sys-light-outline-variant: #cac4cf;
  --m3-sys-light-surface: #f8fafa;
  --color-sandybrown: #f0b269;
  --color-black: #000;
  --color-white: #fff;
  --color-grey: #495057;
  --upflowy-purple-9: #3606A6;
  --dark-dark-7: #1A1B1E;
  --sso-button-grey: #F1F3F5;
  
  /* Gaps */
  --gap-9xs: 0.25rem;
  --gap-5xs: 0.5rem;
  --gap-xs: 0.75rem;
  --gap-5xl: 1.5rem;
  --gap-base: 1rem;

  /* Paddings */
  --padding-3xs: 0.63rem;
  --padding-5xl: 1.5rem;
  --padding-5xs: 0.5rem;
  --padding-9xs: 0.25rem;
  --padding-xl: 1.25rem;
  --padding-base: 1rem;
  --padding-7xs: 0.38rem;
  --padding-xs: 0.75rem;

  /* border radiuses */
  --br-81xl: 100px;
  --br-5xs: 8px;
  --br-base: 16px;
  --br-9xs: 4px;

  /* Effects */
  --m3-elevation-light-1: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --m3-elevation-light-2: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  }

  .mantine-Button-root {
    --button-radius: var(--br-9xs);
  }
`;
