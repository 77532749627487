import { Affix, Card, Overlay } from '@mantine/core';
import { useDebounceCallback } from '@mantine/hooks';
import { IconSparkles } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams, useSearchParams } from 'react-router-dom';

import { BgBlur, HideChat } from './Studio.styles';
import DevEditor from './components/DevEditor/DevEditor';
import PannableOverlays from './components/PannableOverlays/PannableOverlays';
import SecondaryNavigationMenu from './components/SecondaryNavigationMenu/SecondaryNavigationMenu';
import Toolbar from './components/Toolbar';
import VariantMenuExtension from './components/VariantMenuExtension/VariantMenuExtension';
import Viewer from './components/Viewer/Viewer';
import messageHandling from './messageHandling';
import renderPageForEditing from './pageProcessing/renderPageForEditing/renderPageForEditing';
import usePanZoom from './usePanZoom';

import PrimaryNavigationMenu from '../../components/PrimaryNavigationMenu';
import useErrorHandling from '../../hooks/useErrorHandling/useErrorHandling';
import useUndoRedo from '../../hooks/useUndoRedo/useUndoRedo';

import ScreenContainer from '~/components/ScreenContainer/ScreenContainer.styles';
import { LandingpageDetails, ParsedLandingpageObject } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { useStudioWorkspace } from '~/providers/WorkspaceProvider/WorkspaceProvider';
import { firstFetch, subscribeForStatus, updatePage } from '~/services/PageServices';

let isInitialRender = true;
let getDebouncedHTMLStr = '';

const Studio = () => {
  const [searchParams] = useSearchParams();
  const [isGenerating, setIsGenerating] = useState(true);
  const [srcDoc, setSrcDoc] = useState('');
  const { holdMetaKey, isHideOverlay } = usePanZoom();
  const { userAndWorkspaceInfo, featureFlags } = useFeatureFlags();
  const [, setWorkspace] = useStudioWorkspace();
  const { allowDevMode } = featureFlags;
  // We have useStudioWorkspace, chances are, we might not need this anymore
  const { v2ActiveWorkspace } = userAndWorkspaceInfo;
  const workspaceId = v2ActiveWorkspace?.workspaceId ?? '';

  // Various internal related modes
  const isDevModeOn = allowDevMode.enabled && searchParams.get('dev');
  const isPerformanceModeOn = !!(allowDevMode.enabled && searchParams.get('performance'));

  const [pageDetails, setPageDetails] = useState<LandingpageDetails>({
    nanoId: '',
    workspaceId: '',
    name: '',
    definition: '',
    status: '',
  });
  const [pageData, setPageData, undoableFunctions] = useUndoRedo(pageDetails, isPerformanceModeOn);
  const { nanoId = '' } = useParams();
  const handleError = useErrorHandling();

  // Debounce is only applied for dev editor
  const devEditorDebounce =
    isDevModeOn &&
    useDebounceCallback(() => {
      msg({ type: 'dev-editor-sync', htmlStr: getDebouncedHTMLStr });
    }, 700);

  useEffect(() => {
    const dataRefresh = (res: ParsedLandingpageObject) => {
      if (res) {
        const firstFetchPageDetails = {
          name: res.name,
          title: res.title,
          description: res.description,
          definition: res.definition,
          nanoId: res.nanoId,
          workspaceId: res.workspaceId,
          status: res.status,
          gtmId: res.gtmId,
          favicon: res.favicon,
        };

        setPageDetails(firstFetchPageDetails);
        setWorkspace({ workspaceId: res.workspaceId });

        if (res.status === 'INGESTING') {
          const initSubscribe = () =>
            subscribeForStatus(res.nanoId, ({ status }, subscribeResult) => {
              if (status === 'ACTIVE') {
                msg({ type: 'close-toolbar-panel' });
                firstFetch(nanoId).then(dataRefresh).catch(handleError);
                subscribeResult.unsubscribe();
              }
            });

          msg({ type: 'open-toolbar-panel', openToolbarPanel: 'brief-panel' });

          return initSubscribe();
        }

        const firstPageData = res.content ?? '';
        setPageData(firstPageData, undefined, true);
        // Reset undo/redo is needed here, otherwise user can undo to
        // the initial blank state.
        undoableFunctions.reset();

        setIsGenerating(false);
      }
    };

    firstFetch(nanoId).then(dataRefresh).catch(handleError);

    return () => {
      isInitialRender = true;
    };
  }, []);

  useEffect(() => {
    // Safari needs to send this message in an useEffect otherwise the message is broadcast too early
    // resulting brand CSS variables not populated.
    if (!isGenerating) {
      msg({
        type: 'first-fetch-page-data-completed',
        pageData,
        pageDetails,
      });
    }
  }, [isGenerating]);

  useEffect(() => {
    const asyncRenderPage = async () => {
      try {
        const renderedContent = await renderPageForEditing(
          nanoId,
          pageDetails.workspaceId,
          pageData,
          (dataStr: string) => {
            if (!isInitialRender && pageDetails.workspaceId) {
              updatePage(pageDetails.workspaceId, nanoId, {
                content: dataStr,
                definition: pageDetails.definition,
                gtmId: pageDetails.gtmId,
                favicon: pageDetails.favicon,
                description: pageDetails.description,
              }).then((e) => {
                if (e) {
                  msg({ type: 'update-page-content-success' });
                }
              });
            }
            return dataStr;
          },
        );

        setSrcDoc(renderedContent);
        isInitialRender = false;
      } catch (error) {
        console.error('Error in rendering page:', error);
      }
    };

    asyncRenderPage();
  }, [nanoId, pageDetails.nanoId, pageData]); // Add dependencies as required

  useViewerMessage(
    (e) =>
      messageHandling(e, [
        pageData,
        setPageData,
        pageDetails,
        setPageDetails,
        undoableFunctions.past,
      ]),
    [pageData, pageDetails, undoableFunctions.past],
  );

  async function handleBlurUpdatePageName(e: { target: HTMLDivElement }) {
    const newPageName = (e.target.textContent ?? '').trim();
    if (pageDetails.name !== newPageName) {
      await updatePage(workspaceId, nanoId, {
        name: newPageName,
      });
      setPageDetails({
        ...pageDetails,
        name: newPageName,
      });
      return;
    }
  }

  return (
    <ScreenContainer>
      <HideChat />

      {isGenerating ? (
        <Overlay center fixed zIndex={10} bg="transparent">
          <BgBlur />
          <Card shadow="sm" w="280px" padding={40} radius="md" ta="center">
            <Card
              bg="linear-gradient(180deg, #D027D3 0%, #7C1ACB 31.5%, #5E27D3 66.5%, #27AAD3 100%)"
              c="#fff"
              p={36}
              radius={16}
            >
              <IconSparkles size={125} />
            </Card>
          </Card>
        </Overlay>
      ) : (
        <DndProvider backend={HTML5Backend}>
          <PannableOverlays
            show={!isHideOverlay}
            pageDetails={pageDetails}
            allowDevMode={allowDevMode}
            enablePerformanceMode={isPerformanceModeOn}
          />
          <Viewer
            srcDoc={srcDoc}
            holdMetaKey={holdMetaKey}
            pageDetails={pageDetails}
            enablePerformanceMode={isPerformanceModeOn}
          />
        </DndProvider>
      )}

      <Affix position={{ left: 8, top: 8 }} zIndex={9999}>
        <PrimaryNavigationMenu
          list={[{ id: pageDetails.nanoId ?? '', name: pageDetails.name ?? '' }]}
          onBlur={handleBlurUpdatePageName}
          selectedItem={{ id: pageDetails.nanoId ?? '', name: pageDetails.name ?? '' }}
          setSelectItemFromList={() => null}
          showDropdown={false}
          rightSectionComponent={
            !isGenerating && featureFlags.allowPageSets.enabled ? <VariantMenuExtension /> : null
          }
        />
      </Affix>

      {allowDevMode.enabled && searchParams.get('dev') ? (
        <DndProvider backend={HTML5Backend}>
          <DevEditor
            pageDetails={pageDetails}
            pageData={pageData}
            onChange={(htmlStr: string) => {
              getDebouncedHTMLStr = htmlStr;
              if (devEditorDebounce) devEditorDebounce();
            }}
          />
        </DndProvider>
      ) : (
        <>
          <DndProvider backend={HTML5Backend}>
            <Toolbar showBriefPanelOnly={isGenerating} />
          </DndProvider>
          <SecondaryNavigationMenu undoableFunctions={undoableFunctions} />
        </>
      )}
    </ScreenContainer>
  );
};

export default Studio;
