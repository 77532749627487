import {
  ActionIcon,
  Card,
  Group,
  Image,
  LoadingOverlay,
  Menu,
  NavLink,
  rem,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowUpRight,
  IconCopy,
  IconDots,
  IconEdit,
  IconTrash,
  IconWriting,
} from '@tabler/icons-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CopyPageModal from './CopyPageModal';
import PageRenameModal from './PageRenameModal';

import { ParsedLandingpageObject } from '~/global.types';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { deletePage, updatePage } from '~/services/PageServices';

const MenuTargetContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

type PageCardProps = {
  page: ParsedLandingpageObject;
  image?: string;
  cardImageOverride?: React.ReactElement;
  onDeleteSuccess: (pageId: string) => void;
  onCopySuccess: (pageId: string, name: string, definition: string) => void;
  onNameChange: (pageId: string, name: string) => void;
};

const PageCard = ({
  page,
  image,
  cardImageOverride,
  onDeleteSuccess,
  onCopySuccess,
  onNameChange,
  ...props
}: PageCardProps) => {
  const [loader, setLoader] = useState(false);
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { v2ActiveWorkspace } = userAndWorkspaceInfo;

  const workspaceId = v2ActiveWorkspace?.workspaceId ?? '';
  const domain = import.meta.env?.VITE_DOMAIN_NAME ?? '*.test.upflowyexperience.com';
  const { nanoId, name, definition, status } = page;

  const published_url = `https://${domain.replace('*', workspaceId)}/${definition}`;

  const [showEditNameModal, { open: openRenameModal, close: closeRenameModal }] =
    useDisclosure(false);
  const [showCopyPageModal, { open: openCopyModal, close: closeCopyModal }] = useDisclosure(false);
  const navigate = useNavigate();

  const handleNameUpdate = async (updatedName: string) => {
    await updatePage(workspaceId, nanoId, { name: updatedName });
    onNameChange(nanoId, updatedName);
    return true;
  };

  const handleClickDelete = () =>
    modals.openConfirmModal({
      title: 'Delete this page?',
      children: (
        <Text size="sm">
          You are about to delete <b>{name}</b>. This can't be undone.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: async () => {
        try {
          setLoader(true);
          await deletePage({ nanoId: nanoId ?? '' });
          onDeleteSuccess(nanoId);
          notifications.show({
            color: 'green',
            message: 'Page deleted',
            autoClose: 3000,
            icon: <IconCheck />,
          });
        } catch (error) {
          notifications.show({
            color: 'red',
            message: 'Unable to delete page!',
            autoClose: 3000,
          });
        } finally {
          setLoader(false);
        }
      },
    });

  return (
    <Card
      padding="lg"
      radius="md"
      {...props}
      onClick={() => navigate(`/${nanoId}/edit`)}
      style={{ cursor: 'pointer' }}
      shadow="sm"
      withBorder
    >
      <Card.Section>
        {cardImageOverride}
        <Menu withinPortal position="bottom-start" offset={2} withArrow zIndex={400}>
          <MenuTargetContainer data-testid="menu-target" className="custom-menu">
            <Menu.Target>
              <ActionIcon
                variant="filled"
                color="white"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <IconDots style={{ width: rem(18), height: rem(18), color: 'gray' }} />
              </ActionIcon>
            </Menu.Target>
          </MenuTargetContainer>
          <Menu.Dropdown>
            <Menu.Label>Homepage</Menu.Label>
            <Menu.Divider />
            <Menu.Item
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/${nanoId}/edit`);
              }}
              leftSection={<IconEdit style={{ width: rem(16), height: rem(16) }} />}
            >
              Edit Page
            </Menu.Item>
            <Menu.Item
              onClick={(event) => {
                event.stopPropagation();
                openCopyModal();
              }}
              leftSection={<IconCopy style={{ width: rem(16), height: rem(16) }} />}
            >
              Make a copy
            </Menu.Item>
            <Menu.Item
              onClick={(event) => {
                event.stopPropagation();
                openRenameModal();
              }}
              leftSection={<IconWriting style={{ width: rem(16), height: rem(16) }} />}
            >
              Rename
            </Menu.Item>

            <Menu.Item>
              <NavLink
                href={published_url}
                label="Visit live page"
                leftSection={<IconCircleArrowUpRight style={{ width: rem(16), height: rem(16) }} />}
                style={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  textAlign: 'left',
                  justifyContent: 'flex-start',
                }}
                onClick={(event) => event.stopPropagation()}
                target="_blank"
              />
            </Menu.Item>

            <Menu.Item
              onClick={(event) => {
                event.stopPropagation();
                handleClickDelete();
              }}
              leftSection={<IconTrash style={{ width: rem(16), height: rem(16) }} />}
            >
              Move to trash
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <LoadingOverlay visible={loader} />
        {!cardImageOverride && image && (
          <Image src={image} alt={name} fallbackSrc="/placeholder.png" />
        )}
      </Card.Section>
      <Group justify="space-between" mt="md" mb="xs">
        <Text fw={500}>{name}</Text>
      </Group>
      <Text size="sm" c="dimmed">
        {status}
      </Text>
      {showEditNameModal && (
        <PageRenameModal
          name={name ?? 'Unnamed'}
          close={closeRenameModal}
          updatePageName={handleNameUpdate}
        />
      )}
      {showCopyPageModal && (
        <CopyPageModal
          page={{ nanoId, name: name ?? 'unnamed', slug: definition, workspaceId }}
          close={closeCopyModal}
          onCopySuccess={onCopySuccess}
        />
      )}
    </Card>
  );
};

export default PageCard;
