import { Collapse, Text } from '@mantine/core';
import {
  IconBulb,
  IconIcons,
  IconPhoto,
  IconSearch,
  IconSection,
  IconTextSize,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import {
  ActiveBar,
  ButtonContentSubMenu,
  StyledButtonSubMenu,
  SubMenuContainer,
} from '../Toolbar.styles';
import { subPanelCollection } from '../useToolbarOperations';

import { MakeOpenSubPanelObj, SubPanelTypes } from '~/global.types';

interface AddElementMenuProps {
  opened: boolean;
  makeOpenSubPanel?: MakeOpenSubPanelObj;
}

const AddElementMenu = ({ opened, makeOpenSubPanel }: AddElementMenuProps) => {
  const [openedSub, setOpenedSub] = useState('');

  useEffect(() => {
    setOpenedSub(makeOpenSubPanel?.type || '');
  }, [makeOpenSubPanel]);

  const handleClickToToggleSubPanel = (panelName: SubPanelTypes) => {
    setOpenedSub(openedSub === panelName ? '' : panelName || '');
  };

  return (
    <Collapse in={opened} transitionDuration={100} transitionTimingFunction="linear">
      <SubMenuContainer style={{ borderRadius: '0px 4px 4px 4px' }}>
        <StyledButtonSubMenu variant="transparent" color="Gray/Gray 8" className="d-none">
          <ButtonContentSubMenu>
            <IconSearch size={16} />
            <Text size="10px" fw={500}>
              Search
            </Text>
            <ActiveBar></ActiveBar>
          </ButtonContentSubMenu>
        </StyledButtonSubMenu>
        <StyledButtonSubMenu
          onClick={() => handleClickToToggleSubPanel('add-element')}
          aria-label="Add element"
          variant="transparent"
          color="Gray/Gray 8"
        >
          <ButtonContentSubMenu className={'add-element' === openedSub ? 'opened' : ''}>
            <IconIcons size={16} />
            <Text size="10px" fw={500}>
              Elements
            </Text>
            <ActiveBar className={'add-element' === openedSub ? 'opened' : ''}></ActiveBar>
          </ButtonContentSubMenu>
        </StyledButtonSubMenu>
        <StyledButtonSubMenu
          onClick={() => handleClickToToggleSubPanel('add-text')}
          aria-label="Add text"
          variant="transparent"
          color="Gray/Gray 8"
        >
          <ButtonContentSubMenu className={'add-text' === openedSub ? 'opened' : ''}>
            <IconTextSize size={16} />
            <Text size="10px" fw={500}>
              Text
            </Text>
            <ActiveBar className={'add-text' === openedSub ? 'opened' : ''}> </ActiveBar>
          </ButtonContentSubMenu>
        </StyledButtonSubMenu>
        <StyledButtonSubMenu
          onClick={() => handleClickToToggleSubPanel('add-image')}
          aria-label="Add image"
          variant="transparent"
          color="Gray/Gray 8"
        >
          <ButtonContentSubMenu className={'add-image' === openedSub ? 'opened' : ''}>
            <IconPhoto size={16} />
            <Text size="10px" fw={500}>
              Images
            </Text>
            <ActiveBar className={'add-image' === openedSub ? 'opened' : ''}></ActiveBar>
          </ButtonContentSubMenu>
        </StyledButtonSubMenu>
        <StyledButtonSubMenu
          variant="transparent"
          color="Gray/Gray 8"
          aria-label="Add section"
          onClick={() => handleClickToToggleSubPanel('add-section')}
        >
          <ButtonContentSubMenu>
            <IconSection size={16} />
            <Text size="10px" fw={500}>
              Section
            </Text>
            <ActiveBar className={'add-section' === openedSub ? 'opened' : ''}></ActiveBar>
          </ButtonContentSubMenu>
        </StyledButtonSubMenu>
        <StyledButtonSubMenu variant="transparent" color="Gray/Gray 8" className="d-none">
          <ButtonContentSubMenu>
            <IconBulb size={16} />
            <Text size="10px" fw={500}>
              Inspiration
            </Text>
            <ActiveBar></ActiveBar>
          </ButtonContentSubMenu>
        </StyledButtonSubMenu>
      </SubMenuContainer>

      {Object.entries(subPanelCollection).map(([key, obj]) => {
        const Panel = obj.component;
        return (
          <Panel
            key={key}
            opened={openedSub === key}
            handleClickToToggleSubPanel={handleClickToToggleSubPanel}
            dataFromPostMessage={makeOpenSubPanel?.data}
            viewerDataFromPostMessage={makeOpenSubPanel?.viewerData}
          />
        );
      })}
    </Collapse>
  );
};

export default AddElementMenu;
