import { PageJsonSnippetObj } from '~/global.types';
import renderPage from '~/helpers/renderPage/renderPage';

const replaceVHWithPixels = (htmlStr: string) => htmlStr.replace(/\d+vh/g, '667px');
const disableAllLinks = (htmlStr: string) =>
  htmlStr.replace(/href="/g, 'onclick="return false;" href="');

// scriptsForViewer.ts is another entry point seperate from the main bundle,
// only to be loaded inside the viewer frame
const appendScriptsAndStyles = (htmlStr: string) =>
  htmlStr
    .replace(
      '</body>',
      `
    <script defer type="module" src="/src/scriptsForViewer.ts"></script>
    </body>
    `,
    )
    .replaceAll('loading="lazy"', ' ');

const filterHead = (dataHead: PageJsonSnippetObj[]): PageJsonSnippetObj[] => {
  const filteredHead = dataHead.filter(
    (item: PageJsonSnippetObj) => !item.tagName || item.tagName !== 'script',
  );
  return filteredHead;
};

const renderPageForEditing = async (
  pageId = '',
  workspaceId = '',
  data: string,
  successCallback = (dataStr: string) => dataStr,
): Promise<string> => {
  if (!data) {
    return '';
  }

  let dataCopy;

  try {
    dataCopy = JSON.parse(data);
  } catch (e) {
    console.error('Error: Invalid JSON:', e);
    return `<code>'Error: Invalid JSON:'${e}</code>`;
  }

  // removing script tags added by the user from head
  if (dataCopy['head']) {
    dataCopy['head'] = filterHead(dataCopy['head']);
  }

  let htmlStr = await renderPage(pageId, workspaceId, dataCopy);

  htmlStr = disableAllLinks(htmlStr);
  htmlStr = replaceVHWithPixels(htmlStr);
  htmlStr = appendScriptsAndStyles(htmlStr);

  successCallback(data);

  return htmlStr;
};

export default renderPageForEditing;
