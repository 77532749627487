import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;

  .viewport-frame {
    position: absolute;
    top: 40px;
    bottom: 1px;
    left: 0;
    right: 1px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .viewport-device {
    background: #f4f7f9;
  }

  &.mobile {
    width: 400px;
    height: 800px;
    left: 50%;
    margin-left: -200px;
    margin-top: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 45px;
    background: #f4f7f9;

    .viewport-device {
      border-top-left-radius: 45px;
      border-top-right-radius: 45px;
      top: 1px;
      position: relative;
    }

    .viewport-frame {
      border: 20px solid #f4f7f9;
      border-bottom: 60px solid #f4f7f9;
      border-bottom-left-radius: 45px;
      border-bottom-right-radius: 45px;
      background: #fff;

      & > * {
        border-radius: 0;
      }
    }
  }

  &.tablet {
    width: 600px;
    height: 800px;
    left: 50%;
    margin-left: -300px;
    margin-top: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 45px;
    background: #f4f7f9;

    .viewport-device {
      border-top-left-radius: 45px;
      border-top-right-radius: 45px;
      top: 1px;
      position: relative;
    }

    .viewport-frame {
      border: 20px solid #f4f7f9;
      border-bottom: 60px solid #f4f7f9;
      border-bottom-left-radius: 45px;
      border-bottom-right-radius: 45px;
      background: #fff;

      & > * {
        border-radius: 0;
      }
    }
  }

  .viewport-desktop {
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    position: absolute;
    top: 3%;
    bottom: 3%;
    left: 3%;
    right: 3%;
    background: #fff;

    .viewport-device {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid #c4c4c4;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      height: 40px;
      padding-left: 15px;
      background: #f4f7f9;
    }
  }
`;

export const Dot = styled.span`
  height: 10px;
  width: 10px;
  margin: 14px 3px;
  background-color: #c4c4c4;
  border-radius: 50%;
  display: inline-block;
`;

export const MobileDot = styled.span`
  position: absolute;
  top: 12px;
  left: 151px;
  height: 7px;
  width: 7px;
  margin: 14px 3px;
  background-color: #c4c4c4;
  border-radius: 50%;
  display: inline-block;
`;

export const TabletDot = styled.span`
  position: absolute;
  top: 12px;
  left: 250px;
  height: 7px;
  width: 7px;
  margin: 14px 3px;
  background-color: #c4c4c4;
  border-radius: 50%;
  display: inline-block;
`;

export const Sound = styled.span`
  height: 3px;
  background-color: #c4c4c4;
  width: 60px;
  display: inline-block;
  border-radius: 4px;
  position: absolute;
  top: 28px;
  left: 50%;
  margin-left: -30px;
`;
