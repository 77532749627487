import { IconLayersDifference } from '@tabler/icons-react';

import DefineVariant from './DefineVariant';

import BasePanel from '../BasePanel/BasePanel';

import { ToolbarPanelProps } from '~/global.types';

const VariantsPanel = ({ opened, handleClickToToggleSubPanel }: ToolbarPanelProps) => {
  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('page-variants')}
      label="Variants"
      title="Variants"
      icon={<IconLayersDifference />}
    >
      <DefineVariant />
    </BasePanel>
  );
};

export default VariantsPanel;
